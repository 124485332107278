<template>
    <div v-if="assessment" class="  w-full md:w-5/6 2xl:w-4/6 m-auto flex flex-col items-center justify-center p-8 ">
         <div   style="right:-5000px" class="w-A4W m-auto absolute  bg-white  "> 
                <div v-if="assessment.final_level > 3" class="rounded shadow-t3 items-center relative md:mt-0">
    
    
                    <div class="w-full p-8 flex justify-between">
                        <h2 class="text-xl text-t3 font-bold sm:text-3xl dark:text-white">
                            Certificate Preview
                        </h2>
                        <div class="flex gap-3 items-center">
                            <div>
                                <label class="form-check-label inline-block text-gray-500" for="backgrounder">
                                View Background
                                </label>
                                <input type="checkbox" id="backgrounder" v-model="viewBackground"  class="form-check-input appearance-none  h-4 w-4 border border-gray-300 rounded-xl-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"/>
                            </div>
                            <button @click="exportToPDF()" class=" w-48 m-auto font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Download</button>
                            
                        </div>
                    </div>
                    <div v-if="htmlCert" id="certificate" class="relative top-0 h-2page overflow-hidden">
    
                        <div class="h-A4H w-full m-auto text-center relative top-0" >
                            <img v-if="viewBackground" class="w-A4W h-A4H absolute top-0"  width="794" height="1123" src="../assets/cert-frm.svg">
                            
                            <div class="flex flex-col top-24 absolute w-full text-center items-start ">
                                <div class=" font-medium text-xs text-gray-500 ml-auto mr-32 ">
                                    <p class="text-left align-text-top"  >Candidate No: {{assessment.customer_id}}</p>
                                    <p class="text-left align-text-top">ELP TO Certificate No: LIC-ELP-2020/07</p>
                                </div>
                                
                            </div>
                            <div class="flex flex-col absolute w-full justify-center " style="top:19rem">
                                <div class="flex gap-16 px-20">
                                    <div >
                                    <img  class=" h-32 w-32 object-cover object-center rounded-xl mr-6 border-t3 border-2 " :src="assessment.customer_image"/>
                                </div>
                                    <div class="flex-1">
                                        <div class="flex w-full p-8 pt-2 text-center  rounded-xl justify-center ">
    
                                            <h2  class=" font-medium align-text-top " :class="textSize()">{{assessment.certificate_name}}</h2>
                                        </div>
                                        <p class=" text-center font-lighter text-md tracking-wider leading-5 text-sm text-gray-600 w-96 mt-4  mx-auto align-text-top"> has obtained the <span class="font-bold">{{trueLevel}}</span><br>on the English Language Proficiency Test For Pilots<br>with the following scores:</p>
                                    </div>
                                </div>
                                <div v-if="assessment.min_descriptor_levels" class="flex w-full absolute  px-28 justify-between grid grid-cols-3 gap-4 "  style="top:20rem">
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Pronunciation</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.pronunciation}}</p>
                                    </div>
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Structure</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.structure}}</p>
                                    </div>
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Vocabulary</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.vocabulary}}</p>
                                    </div>
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Fluency</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.fluency}}</p>
                                    </div>
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Comprehension</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.comprehension}}</p>
                                    </div>
                                    <div class=" flex items-start gap-2 justify-end">
                                        <p class=" text-sm uppercase align-text-top">Interactions</p>
                                        <p class=" font-lighter flex justify-center items-start rounded  border border-gray-500 h-10 w-10 align-text-top">{{assessment.min_descriptor_levels.interaction}}</p>
                                    </div>
                                    
                                </div>
                                <div class="flex w-full absolute text-3xl "  style="top:27rem">
                                    <p class=" font-bold text-t3 ml-auto mt-0 mr-32 align-text-top">{{assessment.final_level}}</p>
                                </div>
                                <div class="flex w-full absolute justify-between px-24 items-center"  style="top:34rem">
                                    <div class="flex text-left  flex-col">
                                        <p class=" font-lighter text-sm text-gray-500 align-text-top">Issue date:<span class="px-1.5"></span> {{todaydate}}</p>
                                        <p class=" font-lighter text-sm text-gray-500  align-text-top">Expiry date: <span class="px-1"></span>{{expirydate}}</p>
                                    </div>
                                    <div class="flex text-left items-center h-full flex-col">
                                        <img  class="my-auto"  width="150" height="150" src="../assets/stamp.svg">
                                    </div>
                                    <div class="flex text-left  flex-col items-center justify-center">
                                        <img   width="141" height="48" src="../assets/kseniya-sign.svg">
                                        <p class=" font-lighter text-sm text-center text-gray-500 mt-2">Kseniya Kuzniatsova</p>
                                        <p class=" font-lighter text-sm text-center text-gray-500  ">English Language Expert</p>
                                    </div>
                                </div>
                       
                            </div>
                            
                       </div>

                        <div class="h-A4H w-full m-auto text-center relative" >
                            <img v-if="viewBackground" class="w-A4W h-A4H absolute top-0"  src="../assets/ELPCertificate_Page2.png">
                            

                        
                    </div>
                    <div class="h-A4H w-full m-auto text-center relative" >
                        <img v-if="viewBackground" class="w-A4W h-A4H absolute top-0"  src="../assets/ELPCertificate_Page2.png">
                    </div>    
                    </div>
    
                
    
                </div>
                <div v-if="assessment.final_level <= 3" class="rounded-xl shadow-t3  justify-center items-center relative md:mt-0">
                This person has failed.
                </div>
            </div>
    <h1 class="text-xl text-white mb-6  md:text-left w-full  text-center font-bold  md:mr-auto"> Latest ELP Assessment</h1>
      <div class="flex md:flex-row flex-col flex-wrap justify-between items-center   w-full bg-blur px-6 py-4  rounded-xl">
        
        <div class="md:flex-row flex  flex-col md:text-left text-center items-center">
            <img  class="rounded-full flex-auto 2xl:w-24 2xl:h-24 w-24 h-24 md:w-16 md:h-16 object-cover border  md:mx-0 " :src="assessment.customer_image">
            <div class="mx-4 my-auto">

              <h3 class="text-xl 2xl:text-2xl mb-1 text-white mt-auto">{{ assessment.customer_name }}</h3>
              <h3 class="2xl:text-lg text-t3-mutedText mb-auto capitalize">{{ assessment.customer_id}}</h3>
            </div>
          </div>
          <div v-if="assessment.status === 'completed' || assessment.status === 'sealed'" class=" md:mt-0 mt-3 text-t3-mutedText md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Level {{ assessment.final_level }} </h1>
            <div class="text-t3-mutedText ">
              <small>Completed at {{new Date(assessment.completion_time).toLocaleDateString('en-gb',{day:'numeric', month:'short', year:'numeric'})}} at {{ new Date(assessment.completion_time).toLocaleTimeString('en-gb',{hours:'00', minutes:'00'})  }}</small>
            </div>
          </div>
          <div v-if="assessment.status === 'disputed'" class=" md:mt-0 mt-3 text-red-500 md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Disputed Level {{ assessment.final_level }} </h1>
            <div class="text-t3-mutedText ">
              <small>Completed at {{new Date(assessment.completion_time).toLocaleDateString('en-gb',{day:'numeric', month:'short', year:'numeric'})}} at {{ new Date(assessment.completion_time).toLocaleTimeString('en-GB',{hour: "2-digit", minute: "2-digit" })  }}</small>
            </div>
          </div>
          <div v-if="assessment.status === 'booked' " class=" md:mt-0 mt-3 text-t3-mutedText md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Upcoming</h1>
            <div class="text-t3-mutedText ">
              <small>Starts at {{new Date(assessment.date).toLocaleDateString('en-gb',{day:'numeric', month:'short', year:'numeric'})}} at {{ assessment.start_time}}</small>
            </div>
          </div>
          <div v-if="assessment.status === 'ongoing' " class=" md:mt-0 mt-3 text-t3-teritiary animate-pulse md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Ongoing</h1>
           
          </div>
          <div v-if="assessment.status === 'pending' " class=" md:mt-0 mt-3 text-t3-mutedText md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Pending</h1>
           
          </div>
          <div v-if="assessment.status === 'cancelled' " class=" md:mt-0 mt-3 text-red-500 md:text-left text-center">
            <h1 class="text-white font-bold text-2xl">Cancelled</h1>
           
          </div>
          <div  class=" md:mt-0 mt-3 text-t3-mutedText md:text-left text-center flex flex-col md:flex-row gap-2 ">
            <button v-if="(assessment.status === 'completed' || assessment.status === 'sealed') && this.assessment.final_level > 3 && !htmlCert" @click="exportToPDF()" class="p-2 flex flex-col items-center justify-between text-center text-t3-mutedText hover:text-t3-secondary text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>

                Print Certificate
            </button>
            <PulseLoader :loading="htmlCert"  color="#00ADEE" size="16px" class="my-auto"></PulseLoader>
         <!--    <button class="p-2 flex flex-col items-center justify-between text-center text-t3-mutedText hover:text-t3-secondary text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
</svg>

                Provide Feedback
            </button> -->
            <button v-if="assessment.status === 'completed' || assessment.status === 'sealed'" data-bs-toggle="modal"
						data-bs-target="#raiseDisputeModal" class="p-2 flex flex-col items-center justify-between text-center text-red-500 hover:text-red-700 text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
</svg>

                        Raise Dispute
            </button>
            <button v-if="assessment.status === 'completed' || assessment.status === 'sealed'" data-bs-toggle="modal"
						data-bs-target="#bookAssessmentModal" class="p-2 flex flex-col items-center justify-between text-center text-t3-mutedText hover:text-t3-secondary text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
</svg>


                New Assessment
            </button>
          </div>
          <div v-if="assessment.status === 'completed' || assessment.status === 'sealed'" class="basis-full w-full md:mt-0 mt-6  ">
            <h1 @click="showReports = !showReports" class=" text-center pt-2 text-t3-mutedText hover:text-t3-secondary cursor-pointer">Show Assessment Report</h1>
            <Transition name="slidedown">

                <div v-if="showReports">
                    <div class="text-left py-4 ">
                                <div class="flex justify-between items-center">
                                    <div>
                                        <h1 class="text-white font-semibold mb-6">English Language Expert (ELE)</h1>
                                        <!-- <p v-if="assessmentELE" class="text-gray-400 text-md ">{{assessmentELE.full_name}}</p> -->
                                    </div>
                                    <h1 class="text-white text-sm">Awarded level <span class="font-semibold">{{reportE.level}}</span></h1>
                                </div>
                                <p class="text-t3-mutedText text-sm text-justify">{{reportE.comment}}</p>
                                
                            </div>
                            <div class="text-left py-4 ">
                                <div class="flex justify-between items-center">
                                    <div >
                                        <h1 class="text-white font-semibold mb-6">Operational Expert (OPE)</h1>
                                        <!-- <p v-if="assessmentOPE" class="text-t3-mutedText text-md ">{{assessmentOPE.full_name}}</p> -->
                                    </div>
                                    <h1 class="text-white text-sm">Awarded level <span class="font-semibold">{{reportO.level}}</span></h1>
                                </div>
                                <p class="text-t3-mutedText text-sm text-justify">{{reportO.comment}}</p>
                            </div>
                </div>
            </Transition>
          </div>
      </div>
      
      <div class="py-3"></div>
    </div>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
			id="raiseDisputeModal" tabindex="-1" aria-labelledby="raiseDisputeModalTitle" aria-modal="true"
			role="dialog">
			<div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
				<div
					class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
					<div
						class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
						<h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
							Raise Dispute
						</h5>
						<button id="disputeClose" type="button"
							class="btn-close box-content w-4 h-4 p-1 text-t3 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
							data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body relative p-4">
						<p>Clarify Dispute Reason</p>
						<input v-model="disputeReason" type="text" placeholder="Type the reason of dispute"
							class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
					</div>
					<div
						class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
						<button   @click="reqSend" type="button"
							class="inline-block px-6 py-2.5 bg-t3 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-light hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
							
                            Raise Dispute
						</button>
					</div>
				</div>
			</div>
		</div>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
			id="bookAssessmentModal" tabindex="-1" aria-labelledby="bookAssessmentModalTitle" aria-modal="true"
			role="dialog">
			<div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
				<div
					class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
					<div
						class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
						<h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
							Send a Booking Request
						</h5>
						<button id="assessmentClose" type="button"
							class="btn-close box-content w-4 h-4 p-1 text-t3 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
							data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body relative p-4">

                            <div class=" m-auto mt-12 text-center">
                                                <div class=" relative">
                                                    <label for="datepick" class="text-sm text-gray-400 mt-8">Highlighted dates have available slots </label>
                                                    <Datepicker id="datepick" :min-date="new Date()" @update:modelValue="newSlots" v-model="assessDate" :highlight="highlighted" :enableTimePicker="false" datePicker placeholder="Select Date"  class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                                                    <select  v-model="req.slot" class="h-12  form-control block w-full p-3 text-xl font-normal text-gray-400 bg-white focus:text-t3 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                                        <option v-if="freeSlots.length === 0" disabled :value="null">No Slots Available for the Selected Date</option>
                                                        <option v-if="freeSlots.length !== 0" disabled :value="null">Select Slot</option>
                                                        <option v-for="slot in freeSlots"  :value="slot.id" :key="slot.id">Date:{{new Date(slot.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}} Time:{{slot.start_time}}</option> 
                                                    </select>
                                                </div>
                                              
                            </div>
					</div>
					<div
						class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
						<button   @click="BookReqSend" type="button"
							class="inline-block px-6 py-2.5 bg-t3 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-light hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
							Send Request
						</button>
					</div>
				</div>
			</div>
		</div>
    <div v-if="!assessment" class="  w-full md:w-5/6 2xl:w-4/6 m-auto flex flex-col items-center justify-center p-8 ">
       
   
      <div class="flex md:flex-row flex-col justify-between items-center   w-full bg-blur p-6  rounded-xl">
        
        <div class="md:flex-row flex flex-col md:text-left text-center items-center">
            <img  class="rounded-full flex-auto w-24 h-24 object-coverborder  md:mx-0 " :src="customerDeets.profile_img">
            <div class="mx-4 my-auto">

              <h3 class="text-2xl mb-1 text-white mt-auto">{{ customerDeets.full_name }}</h3>
              <h3 class="text-lg text-t3-mutedText mb-auto capitalize">{{ customerDeets.id}}</h3>
            </div>
          </div>
          
          <div class=" md:mt-0 mt-3 text-t3-mutedText md:text-left text-center flex flex-col md:flex-row gap-2 ">
            
            <button data-bs-toggle="modal"
						data-bs-target="#bookAssessmentModal" class="p-2 flex flex-col items-center justify-between text-center text-t3-mutedText hover:text-t3-secondary text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
</svg>


                New Assessment
            </button>
          </div>
      </div>
      
      <div class="py-3"></div>
    </div>
    
    
    </template>
    
    <script>
    import { mapActions, mapState, mapGetters } from 'vuex'
    import AssessmentsServices from "../services/AssessmentsServices" 
    import BookingServices from "../services/BookingServices"
    import html2pdf from "html2pdf.js";
    import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
    import { watchEffect } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import { ref } from 'vue';
import addDays from 'date-fns/addDays';
        export default {
            components: {
                Datepicker ,
                PulseLoader
            },
        setup() {
        const date = ref(new Date());
         const highlightedDates = ref([
          addDays(new Date(), 1),
          addDays(new Date(), 2),
          addDays(new Date(), 3),
        ])
        
        return {
            date,
            highlightedDates
            
        }
        },
            data() {
                return {
                   authLink: 'http://' + process.env.VUE_APP_AUTH_URL + '/profile/',
                   loading:true,
                   long:false,
                    viewBackground:true,
                    stepper:'3',
                    assessment:null,
                    disputeReason:null,
                    highlighted:[],
                freeSlots:[],
                showReports:false,
                assessDate:null,
                req:{
                    slot:null
                },
                htmlCert:false,
                }
            },
    
            methods: {
    
             ...mapActions( {
                getreportELE: 'Assessments/getELEReport',
				getreportOPE: 'Assessments/getOPEReport',
                getassessment: 'Assessments/getCertAssessment',
                    getassessments: 'Assessments/getAssessmentsPage',
                    getdocuments: 'Documents/getDocuments',
                    getsettings: 'Settings/getAllSettings',
                getbooked: 'Requests/getBookings',
                getslots: 'Slots/getSlotsForDate',
                getallslots: 'Slots/getAllSlots',
            }),
            newSlots(data){
            if(data){
                
                this.assessDate=data
                let dat= this.assessDate.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                    if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    }}
                )
            }  
        },
            BookReqSend(){
                if(this.req.slot)
                {
                    let userid= JSON.parse(localStorage.getItem('userdetail')).id
                    let sendingObj = {
                        customer:userid,
                        slot:this.req.slot,
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    BookingServices.newBooking(getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Booking request sent",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                        this.getbooked()
                        this.updateSlots()
                        this.freeSlots = this.slots.filter(slot => slot.assessment === null)
                        this.$forceUpdate()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
            bookingCustomer(sentID){
			return this.customers.find(cus => cus.id === sentID)},
            bookingSlot(sentID){
			return this.slots.find(cus => cus.id === sentID)},	
            newRequestModal(){
                document.getElementById("vv").click()
            },
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
                    
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	,
                textSize(){
            let size = "text-lg"
            switch(this.stepper){
                case '0':
                    size = "text-xs mb-4"
                    break;
                case '1':
                    size = "text-sm mb-3"
                    break;
                case '2':
                    size = "text-base mb-2"
                    break;
                case '3':
                    size = "text-lg mb-1"
                    break;
                case '4':
                    size = "text-xl mb-1"
                    break;
                case '5':
                    size = "text-2xl"
                    break;
                
            }
            return size
          },
          reqSend(){
                if(this.disputeReason )
                {
                    let sendingObj = {
                        reason_of_dispute:this.disputeReason,
                        type:"external",
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    AssessmentsServices.raiseDispute(this.assessment.id,getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Dispute raised!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                                this.disputeReason=null
								this.$forceUpdate()
								document.getElementById('disputeClose').click()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
            exportToPDF() {
                this.htmlCert = true
                setTimeout(()=>{
                    const element = document.getElementById('certificate');
                const opt = {
                    margin:       0,
                    filename:     this.assessment.customer_name+'-'+this.assessment.date+'.pdf',
                    image:        { type: 'jpeg', quality: 0.95 },
                    html2canvas:  { scale: 2 ,useCORS: true },
                    jsPDF:        { orientation: 'portrait' }
                };
                html2pdf().set(opt).from(element).toPdf().save()
                },2000)
                setTimeout(()=>{
                this.htmlCert =  false
                },4000)
           
            }
             },
    
            computed: {
             ...mapState(
                    {
                        reportO: state => state.Assessments.opereport,
			reportE: state => state.Assessments.elereport,
                    assessments: state => state.Assessments.assessments,
                    settings: state => state.Settings.settings,
                    assessment: state => state.Assessments.cert_assessment,
                    documents: state => state.Documents.documents,
                    allSlots: state => state.Slots.slots,
                    slots: state => state.Slots.slotD,
                    booked: state => state.Requests.bookings,
                    customers: state => state.Users.customers
            }),
            openBookings(){
                return this.booked.filter(b => b.status === "sent" )
            },

            ...mapGetters('Users', [
            'customerOnly'
            ]),
                customerDeets(){
                    return JSON.parse(localStorage.getItem('userdetail'))
                },
                trueLevel(){
            let sus=""
            switch(this.assessment.final_level){
                case 6:
                    sus = "Expert Level (6)"
                    break;
                case 5:
                    sus = "Extended Level (5)"
                    break;
                case 4:
                    sus = "Operational Level (4)"
                    break;
            }
            return sus
          },
                  assessmentCustomer() {
                    return this.customer
                },
                todaydate(){
                    let date = new Date(this.assessment.completion_time)
                    
                    return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
                },
                expirydate(){
                    let date = new Date(this.assessment.completion_time)
                            switch(this.assessment.final_level){
                    case 6:
                        date= "Lifetime"
                        break;
                    case 5:
                        date.setFullYear(date.getFullYear() + 6);
                        date.setDate(date.getDate()-1)
                        break;
                    case 4:
                        date.setFullYear(date.getFullYear() + 3);
                        date.setDate(date.getDate()-1)
                        break;
                    }
                    if(this.assessment.final_level === 6){
                        return date
                    }
                    else{
                        return date.toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})
                    }
                    
                }
            },
    
            created() {
                
                AssessmentsServices.getAssessmentCustomer(this.customerDeets.id).then((response)=>{
                    this.assessment = response.data[0]
                    this.getreportELE(response.data[0].id)
			this.getreportOPE(response.data[0].id)
                })
                watchEffect(() => {
             if(this.assessDate){
                let dat= this.assessDate.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                this.freeSlots = this.slots.filter(slot => slot.assessment === null)

            })
                }
                            if(this.allSlots){
                this.highlighted=[]
                    this.allSlots.filter(slot => slot.assessment === null).forEach((s)=>{
                        this.highlighted.push(new Date(s.date))
                    })
                    }
            
        
            
            })
            this.getallslots()
            this.getbooked()
            },
        }
    </script>
    
    <style>
    
    </style>
